@import '~@salesforce-ux/design-system/scss/_design-tokens.scss';

pre {
  margin: 0;
  white-space: pre-wrap;
}

.dig-trace-message {
  font-size: $font-size-5;
}

.search-bar {
  display: flex;
  padding-top: $spacing-medium;

  .slds-form-element {
    flex: auto;
    padding-right: $spacing-small;
  }
}

