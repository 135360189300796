@import '~@salesforce-ux/design-system/scss/_design-tokens.scss';

.domain-data {
  border: 1px solid $button-color-border-primary;
}

.domain-data-container {
  margin: $spacing-small;
}

.domain-textarea {
  height: $size-x-small;
}
